import React from "react"
import Helmet from "react-helmet"
// import Link from "gatsby-plugin-transition-link"

import PageLink from "../components/pageLink"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./contact.scss"
import { onBackToMain, BASE_TRANSITION_LENGTH } from "../transitions"

const ContactPage = ({ transitionStatus }) => (
  <>
    <Helmet
      bodyAttributes={{
        class: "page contact",
      }}
      key="helmet"
    />
    ,
    <Layout transitionStatus={transitionStatus} page="contact">
      <SEO title="Contact" />
      <PageLink
        to="/"
        className="transitionLink transitionLink--home"
        exit={{ length: BASE_TRANSITION_LENGTH }}
        entry={{
          length: 0,
          trigger: args => onBackToMain("contact", args),
        }}
      ></PageLink>
      <a className="telephoneLink" href="tel:+442072874060">
        +44 (0)207 287 4060
      </a>
      <a className="rupertLink" href="mailto:rupert@gorgeous.tv">
        rupert@gorgeous.tv
      </a>
      <a className="emailLink" href="mailto:ableseaman@gorgeous.tv">
        ableseaman@gorgeous.tv
      </a>
    </Layout>
    ,
  </>
)

export default ContactPage
